import React, { FC, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { Container } from '../container'
import { StatusBadge } from '../status-badge'
import { Text } from '../typography'
import { DeletedAvatar } from './deleted'
import {
  AvatarStatusBadgeProps,
  AvatarImageProps,
  AvatarVariant,
  GeneralAvatarProps,
} from './types'

const DEFAULT_SIZE = '3.6rem'
const DEFAULT_FONT_SIZE = '1.6rem'
const DEFAULT_BORDER_RADIUS = '0.8rem'

function parseAvatarStyles(variant?: AvatarVariant, borderRadius?: string) {
  switch (variant) {
    case AvatarVariant.SQUARE:
      return css`
        border-radius: ${borderRadius || DEFAULT_BORDER_RADIUS};
      `
    case AvatarVariant.CIRCLE:
    default:
      return css`
        border-radius: 50%;
      `
  }
}

export const Avatar: FC<GeneralAvatarProps> = ({
  variant = AvatarVariant.SQUARE,
  label,
  name,
  bgColor,
  imgUrl,
  size = DEFAULT_SIZE,
  fontSize = DEFAULT_FONT_SIZE,
  iconSize,
  flag,
  status,
  borderRadius,
  isDeleted,
  firstName,
  lastName,
  icon,
  isAvatarExists,
  onClick,
  ...stylesProps
}) => {
  const imgRef = React.useRef<HTMLImageElement>(null)

  const [isError, setIsError] = useState(false) //fallback to initials if image fails to load
  const isImgDisplayed = imgUrl && !isError

  const getBgColor = useMemo(() => {
    if (isDeleted) return 'grey'
    if (isAvatarExists) return 'greyLight'
    if (bgColor) return bgColor
    return 'greyLight'
  }, [isDeleted, isAvatarExists])

  return (
    <Container
      width={size}
      height={size}
      position="relative"
      onClick={onClick}
      as={onClick ? 'button' : 'div'}
      display="block"
      aria-label={label}
      flex="0 0 auto" // keep dimensions when the Avatar is in flexbox
      {...stylesProps}
    >
      <AvatarImageContainer
        backgroundColor={getBgColor}
        variant={variant}
        borderRadius={borderRadius}
        as="span"
      >
        {isDeleted ? (
          <DeletedAvatar iconSize={iconSize} flag={flag} />
        ) : (
          <>
            {isImgDisplayed ? (
              <img
                ref={imgRef}
                src={imgUrl}
                alt={name || `${firstName} ${lastName}`}
                onError={() => {
                  setIsError(true)
                }}
              />
            ) : (
              !isAvatarExists &&
              (label || firstName) && (
                <Label as="span" fontSize={fontSize}>
                  {label?.charAt(0) ?? firstName?.charAt(0)}
                </Label>
              )
            )}
          </>
        )}
        {icon && icon}
      </AvatarImageContainer>
      {status && !isDeleted && (
        <StatusBadgeContainer>
          <StatusBadge status={status} />
        </StatusBadgeContainer>
      )}
    </Container>
  )
}

const AvatarImageContainer = styled(Container)<AvatarImageProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 100%;
  color: ${({
    theme: {
      colors: { white },
    },
  }) => white};
  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ${({ variant, borderRadius }) => parseAvatarStyles(variant, borderRadius)};
`

const StatusBadgeContainer = styled.span<AvatarStatusBadgeProps>`
  position: absolute;
  bottom: -0.25rem;
  right: -0.25rem;
  height: 1.2rem;
  width: 1.2rem;
`

const Label = styled(Text)`
  text-transform: uppercase;
`
