import React from 'react'

export const PlayIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M19.3456 11.1176C20.0515 11.4941 20.0515 12.5059 19.3456 12.8824L7.47059 19.2157C6.80448 19.5709 6 19.0883 6 18.3333L6 5.66667C6 4.91175 6.80448 4.42906 7.47059 4.78431L19.3456 11.1176Z" />
    </svg>
  )
}
