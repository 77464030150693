import React from 'react'

export const LogoGoogle: React.FC<React.SVGProps<SVGSVGElement>> = (
  props: React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg width="25" height="24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path
          d="M21.333 11.718c0-.646-.05-1.295-.158-1.93h-8.661v3.658h4.96a4.41 4.41 0 0 1-1.836 2.887v2.374h2.96c1.737-1.655 2.735-4.099 2.735-6.989Z"
          fill="#4285F4"
        />
        <path
          d="M12.514 21c2.476 0 4.565-.841 6.086-2.293l-2.959-2.374c-.823.58-1.886.908-3.124.908-2.395 0-4.426-1.672-5.155-3.92H4.308v2.446C5.868 18.975 9.042 21 12.514 21Z"
          fill="#34A853"
        />
        <path
          d="M7.359 13.32a5.877 5.877 0 0 1 0-3.637V7.236h-3.05a9.803 9.803 0 0 0 0 8.531l3.05-2.447Z"
          fill="#FBBC04"
        />
        <path
          d="M12.514 5.76a4.9 4.9 0 0 1 3.522 1.424l2.622-2.713A8.664 8.664 0 0 0 12.514 2C9.042 2 5.867 4.025 4.308 7.236l3.05 2.447c.726-2.251 2.76-3.923 5.156-3.923Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath>
          <path fill="#fff" transform="translate(3.333 2)" d="M0 0h18v19H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
